import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster:  'pusher',
    key:          process.env.VUE_APP_PUSHER_KEY,
    authEndpoint: `${process.env.VUE_APP_CORE_API_URL}/broadcasting/auth`,
    cluster:      'eu',
    forceTLS:     true,
    auth:         {
        headers: {
            Authorization: axios.defaults.headers.common['Authorization'],
        },
    },
});
