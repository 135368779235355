<template>
    <div v-if="data === 0 && !$wait.is(waitKey)" class="w-full h-full flex flex-col items-center justify-center">
        <img src="@/assets/images/undraw_empty_result.svg" class="mb-10" alt="" :width="width">
        <p class="mb-20">
            {{ $t('common.sorry_no_results_for_that_data') }}
        </p>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type:     [Boolean, Number],
            required: true,
        },
        waitKey: {
            type:     String,
            required: true,
        },
        width: {
            type:    String,
            default: '300px',
        },
    },
};
</script>
