<template>
    <button
        type="button"
        :disabled="disabled"
        :class="[
            type === 'normal' ? outLineClass : '',
            type === 'danger' ? dangerClass : '',
            disabled || loading ? disabledClass : '',
        ]"
        @click="click"
    >
        <fa-icon v-if="loading" :icon="['fad', 'spinner']" spin fixed-width class="text-xl mr-2" />
        <slot />
    </button>
</template>
<script>
export default {
    props: {
        type: {
            type:    String,
            default: 'normal',
        },
        disabled: {
            type:    Boolean,
            default: false,
        },
        loading: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            outLineClass: `w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium 
                text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 
                sm:w-auto sm:text-sm`,
            dangerClass: `w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base 
                font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 
                sm:w-auto sm:text-sm`,
            disabledClass: 'bg-opacity-50 hover:bg-opacity-50 cursor-not-allowed',
        };
    },

    methods: {
        click() {
            this.$emit('click');
        },
    },
};
</script>
