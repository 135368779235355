<template>
    <div>
        <SideBar />
        <div class="content-right" :class="{ 'collapsed': menuCollapsed }">
            <TopBar />
            <div class="router-content">
                <!-- <transition mode="out-in"> -->
                <router-view />
                <!-- </transition> -->
            </div>
        </div>
    </div>
</template>
<script>
import Cookies from 'js-cookie';
import '@/plugins/pusher';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import InitialLoader from '@/components/shared/InitialLoader.vue';
import Button from '@/components/shared/Button.vue';
import NoDataInformation from '@/components/shared/NoDataInformation.vue';
import Vue from 'vue';

Vue.component('ConfirmationModal', ConfirmationModal);
Vue.component('InitialLoader', InitialLoader);
Vue.component('NoDataInformation', NoDataInformation);
Vue.component('Button', Button);

export default {
    components: {
        SideBar: () => import(/* webpackChunkName: "SideBar" */ '@/components/sidebar/SideBar.vue'),
        TopBar:  () => import(/* webpackChunkName: "TopBar" */ '@/components/topbar/TopBar.vue'),
    },

    computed: {
        menuCollapsed() {
            return this.$store.state.menuCollapsed;
        },
    },

    async created() {
        this.getUserInformation();
        this.getClientsToDropdown();
    },

    methods: {
        async getUserInformation() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/settings/get_website_settings`);
            this.$store.commit('user/updateUser', data);

            this.$i18n.locale = data.language;
            Cookies.set('language', data.language, { expires: 365 });
            Cookies.set('currency', data.country === 'Sweden' ? 'kr' : 'zł', { expires: 365 });

            this.initCrispChat(data);
        },

        async getEmployeesToDropdown() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/to_dropdown`);
            this.$store.commit('setActiveEmployeesDropdown', data.active);
            this.$store.commit('setArchivedEmployeesDropdown', data.archived);
        },

        async getClientsToDropdown() {
            // if (!this.$store.state.user.user.master_uuid) return;
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/to_dropdown`);
            this.$store.commit('setActiveClientsDropdown', data.active);
        },


        initCrispChat(data) {
            if (process.env.VUE_APP_ENV === 'development' || Cookies.get('master_cookie')) return;

            window.$crisp = [];
            window.CRISP_WEBSITE_ID = '31e4476a-cd03-491a-9e82-fe66d8f2c0e8';
            window.CRISP_TOKEN_ID = data.user_uuid;
            (() => {
                const d = document;
                const s = document.createElement('script');
                s.src = 'https://client.crisp.chat/l.js';
                s.async = 1;
                document.getElementsByTagName('head')[0].appendChild(s);
                $crisp.push(['set', 'user:email', Cookies.get('user_email')]);
                $crisp.push(["set", "user:nickname", data.welcome_title]);
                $crisp.push(['do', 'chat:open']);
                $crisp.push(['do', 'chat:hide']);
                $crisp.push(['safe', true]);
                $crisp.push(['on', 'message:received', () => {
                    $crisp.push(['do', 'chat:show']);
                }]);
                $crisp.push(['on', 'chat:closed', () => {
                    $crisp.push(['do', 'chat:hide']);
                }]);
            })();
        },
    },
};
</script>
<style scoped>
    .content-right {
        margin-left: 245px;
    }

    .router-content {
        padding-top: 15px;
        padding-bottom: 1px;
        padding-right: 15px;
    }

    .collapsed {
        margin-left: 75px;
    }
</style>
