<template>
    <transition>
        <div v-show="visible" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div class="absolute inset-0 bg-gray-900 opacity-75" />
                </div>

                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div
                    class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all
                    sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-8 sm:pb-6">
                        <div class="sm:flex sm:items-start">
                            <div
                                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100
                                sm:mx-0 sm:h-10 sm:w-10"
                            >
                                <svg
                                    class="h-6 w-6 text-red-600"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732
                                        4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.771.333.192 3 1.732 3z"
                                    />
                                </svg>
                            </div>
                            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 id="modal-headline" class="text-lg leading-6 font-medium text-gray-900">
                                    {{ $t('common.are_you_sure') }}
                                </h3>
                                <div class="mt-2">
                                    <p v-if="actionType === 'destroy'" class="text-sm text-gray-500">
                                        {{ $t('common.confirmation_destroy') }}
                                    </p>
                                    <p v-else class="text-sm text-gray-500">
                                        {{ $t('common.confirmation_archive') }}
                                    </p>
                                    <slot name="extra-content" />
                                    <div v-if="codeConfirmation" class="mt-8 flex items-center">
                                        <p class="text-sm text-gray-500 mr-2">
                                            {{ $t('common.to_confirm_repeat_this_code') }}
                                        </p>
                                        <p>
                                            {{ confirmationCode }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="codeConfirmation">
                        <ConfirmationCode
                            :modalVisible="visible"
                            @codeChange="confirmationCode = $event"
                            @checkCode="confirmationDisable = $event"
                        />
                    </div>
                    <div class="bg-gray-50 px-4 py-3 pb-6 sm:px-6 sm:flex sm:flex-row-reverse">
                        <Button ref="confirm" type="danger" :loading="$wait.is('deleting')" :disabled="!confirmationDisable" @click="confirm">
                            {{ $t('common.confirm') }}
                        </Button>
                        <Button :disabled="$wait.is('deleting')" @click="close">
                            {{ $t('common.cancel') }}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    components: {
        ConfirmationCode: () => import(/* webpackChunkName: "ConfirmationModal/ConfirmationCode" */ './components/ConfirmationCode'),
    },

    props: {
        codeConfirmation: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            successCounter:      0,
            confirmationDisable: !this.codeConfirmation,
            confirmationCode:    null,
        };
    },

    computed: {
        visible() {
            return this.$store.state.confirmationVisible;
        },
        actionType() {
            return this.$store.state.confirmationActionType;
        },
        actionUrl() {
            return this.$store.state.confirmationActionUrl;
        },
    },

    watch: {
        visible(value) {
            if (!value) return;
            document.addEventListener('keyup', this.onKeyPressed);
        },
    },

    methods: {
        async confirm() {
            this.$wait.start('deleting');

            try {
                await axios.delete(this.actionUrl);
                this.success();
            } catch {
                this.close();
            }
        },

        close() {
            this.$wait.end('deleting');
            document.removeEventListener('keyup', this.onKeyPressed);
            this.$store.commit('closeConfirmationModal');
        },

        success() {
            this.$notify.success({ title: this.$t('common.success') });
            this.$emit('success');
            this.close();
        },

        onKeyPressed(event) {
            if (event.keyCode === 27) this.close();
            if (event.keyCode === 13 && this.confirmationDisable) {
                this.$refs.confirm.click();
            }
        },
    },
};
</script>
<style>
    .code-input .el-input__inner {
        height: 80px;
        width: 80px;
        text-align: center;
        font-size: 50px;
    }
</style>
